import React, { memo } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { Heading } from "./Primitives";
import Fancy from "./Fancy";
import Tag from "./Tag";
import { previewPostType } from "../types";

const Container = styled.li`
  overflow-x: hidden;
  background-color: var(--card);
  color: var(--color);
  border-top: 1px solid var(--bg-border);
  border-left: 1px solid var(--bg-border);
  border-right: 3px solid var(--bg-border);
  border-bottom: 3px solid var(--bg-border);
  border-radius: 5px;
  padding: var(--rhythm);
  margin-left: 0 !important;
  margin-bottom: calc(var(--rhythm) / 2);
  box-shadow: 0 0 4px 2px var(--card-shadow);
  @media screen and (max-width: 576px) {
    padding: calc(var(--rhythm) / 2);
  }
`;

const HeadingLink = styled(Link)`
  display: inline-block;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  line-height: 1.1;
  color: var(--color);
  background-color: transparent !important;
  transition: color 0.2s ease-in-out;
  text-decoration: underline;

  :hover {
    color: var(--accent);
  }

  :active {
    box-shadow: none;
  }
`;
const PostInfo = styled.div`
  margin-bottom: calc(var(--rhythm) / 2);
`;
const PostExcerpt = styled.p`
  margin: var(--rhythm) 0 0;
  @media screen and (max-width: 576px) {
    margin: calc(var(--rhythm) / 2) 0 0;
  }
`;

const areEqual = (prev, next) => {
  if (prev.post !== next.post && prev.slug === next.slug) return true;
  return false;
};

const PostCard = memo(
  ({ post: { title, description, slug, timeToRead, date, tags } }) => (
    <Container className="post-card">
      <Heading mb={2} fontSize="1.8em">
        <HeadingLink to={slug}>{title}</HeadingLink>
      </Heading>
      <PostInfo>
        <Fancy>{date}</Fancy>
        <Fancy>
          <span>Time to read: </span>
          {timeToRead}
          <span> mins</span>
        </Fancy>
      </PostInfo>
      {tags.map((tag) => (
        <Tag key={tag} tag={tag} />
      ))}
      <PostExcerpt>{description}</PostExcerpt>
    </Container>
  ),
  areEqual
);

PostCard.propTypes = {
  post: previewPostType.isRequired,
};

export default PostCard;
