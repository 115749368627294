import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import urljoin from "url-join";
import orderBy from "lodash.orderby";

import SEO from "../components/SEO";
import PostList from "../components/PostList";
import PostCard from "../components/PostCard";
import edgeToPost from "../utils/edgeToPost";
// import { chunk } from '../utils/chunk-array';

const IndexPage = ({
  data: {
    site: {
      siteMetadata: { siteUrl, description, title, card },
    },
    mdx: { edges: mdxEdges },
  },
}) => {
  const posts = mdxEdges.map(edgeToPost);
  const sortedPosts = orderBy(posts, "dateSort", "desc");
  return (
    <Fragment>
      <SEO
        title={`${title}`}
        description={description}
        image={urljoin(siteUrl, card)}
        url={siteUrl}
      />
      <PostList data-test-id="post-list">
        {sortedPosts.map((post) => (
          <PostCard key={post.slug} post={post} />
        ))}
        {/* {chunk(sortedPosts)(6).map(postChunk => (
          <section
            className="content-visibility-chunk"
            key={postChunk[0].slug}
          >
            {postChunk.map(post => (
              <PostCard key={post.slug} post={post} />
            ))}
          </section>
        ))} */}
      </PostList>
    </Fragment>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        siteUrl: PropTypes.string.isRequired,
        card: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    mdx: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      edges: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        description
        title
        card
      }
    }
    mdx: allMdx(
      filter: { frontmatter: { published: { eq: true } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 666
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            dateSort: date
            title
            description
            tags
          }
          timeToRead
        }
      }
    }
  }
`;

export default IndexPage;
